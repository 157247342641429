
.discription{
    border-radius: 10px;
    color:black;
    font-size: 1.7rem;
    text-align: center;
    display:grid;
    grid-template-areas: "img desc desc"
     "img desc desc"
     "img link link";
     align-items: center;
    background-color: #eee;
    margin-inline:100px;
    padding:20px;
}

.card-desc{
    margin-inline:100px;
    grid-area:desc;
    margin-bottom: 5vh;
}
.Img-desc{
    grid-area: img;
    width: 100px;
    height: 140px;
    border:3px solid black;
}
.Img-desc>img{
    object-fit: contain;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 20px 0px #FF8C32;
}
.relLinks{
    grid-area:link;
}

@media only screen and (max-width: 950px) {
    .discription {
      grid-template-areas: "img img img"
      "desc desc desc"
      "link link link";
      justify-items: center;
        font-size: 0.5rem;
        font-weight: bold;
        margin:0;
        padding:10px 0;
    }
    .card-desc{
      position: relative;
      top:20px;
      grid-area:desc;
      margin-bottom: 5vh;
  }
    
  }

  .button-54 {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
    color: #000;
    cursor: pointer;
    border: 3px solid;
    padding: 0.25em 0.5em;
    box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px, 5px 5px 0px 0px;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-54:active {
    box-shadow: 0px 0px 0px 0px;
    top: 5px;
    left: 5px;
  }
  
  @media (min-width: 768px) {
    .button-54 {
      padding: 0.25em 0.75em;
    }
  }