
.pandetaList{
    margin-top: 5vh;
    display: grid;
    gap:10px;
    grid-template-columns: repeat(auto-fit,minmax(200px,1fr));
    align-items: center;
    justify-items: center;
    margin-inline: 100px;
    margin-bottom:40px;
}
.card{
    background-color: rgba(43,43,12, 0.8);
    max-height: 100%;
    border-radius: 6px;
 
}
.card:hover{
    cursor: pointer;
    background-color: rgba(43,43,12, 0.5);

}
.card img{
    margin-top:8px;
    width:80%;
    height: 60%;
    transition: all 0.3s linear;
}
.card:hover img{
    transform: scale(0.9);
}
.card-text{
    text-align: center;
    font-weight: bold;
    font-size: 1.3rem;
    padding-bottom: 20px;
    
}